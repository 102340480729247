import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InputNumberModule } from 'primeng/inputnumber';
import { PasswordModule } from 'primeng/password';
import { NoopValueAccessorDirective } from '../../directives';
import { ControlInjectedInputPasswordComponent } from './control-injected-input-password.component';

@UntilDestroy()
@Component({
  selector: 'prime-input-password',
  templateUrl: './prime-input-password.component.html',
  styleUrls: ['./prime-input-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, InputNumberModule, ReactiveFormsModule, PasswordModule],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  hostDirectives: [NoopValueAccessorDirective]
})
export class PrimeInputPasswordComponent extends ControlInjectedInputPasswordComponent {
  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
