import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMAIL_PATTERN } from '@pinnakl/shared/constants';
import { stringBooleanToBoolean } from '@pinnakl/shared/util-helpers';
import { AuthCacheService } from '../../services/auth-cache.service';
import { AuthenticatorService } from '../../services/authenticator.service';

interface ForgotPasswordForm {
  email: FormControl<string | null>;
}

@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  forgotPasswordForm: FormGroup<ForgotPasswordForm> = new FormGroup<ForgotPasswordForm>({
    email: new FormControl(null, {
      nonNullable: false,
      validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)]
    })
  });
  submitted = false;

  isLinkExpired = false;
  generatedLinkSent = false;

  constructor(
    private readonly authenticator: AuthenticatorService,
    private readonly authCacheService: AuthCacheService
  ) {
    const params = new URLSearchParams(this.authCacheService.lastURLSearch);
    const isLinkExpired = params.get('isLinkExpired');
    this.isLinkExpired = stringBooleanToBoolean(isLinkExpired);
  }

  forgotPassword(): void {
    this.submitted = true;
    if (!this.forgotPasswordForm.valid) {
      return;
    }
    const { email } = this.forgotPasswordForm.value;
    this.authenticator.regenerateMagicLink(email.trim(), () => {
      this.generatedLinkSent = true;
    });
  }

  backToLogin(): void {
    this.authenticator.redirectToSource();
  }
}
