import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { LocalSpinnerSimpleModeContainerComponent } from '@pinnakl/shared/base-components';
import { EmptyDataBlockComponent } from '@pinnakl/shared/domain-components';
import {
  InputContainerComponent,
  InputValidationComponent,
  LabelContentComponent,
  PrimeButtonComponent,
  PrimeSingleDropdownComponent
} from '@pinnakl/shared/ui/prime';
import { AuthenticatorService } from '../../services/authenticator.service';

@Component({
  standalone: true,
  selector: 'pinnakl-select-portal-client',
  templateUrl: './select-portal-client.component.html',
  styleUrls: ['./select-portal-client.component.scss'],
  imports: [
    LocalSpinnerSimpleModeContainerComponent,
    EmptyDataBlockComponent,
    PrimeSingleDropdownComponent,
    ReactiveFormsModule,
    InputContainerComponent,
    LabelContentComponent,
    InputValidationComponent,
    PrimeButtonComponent,
    JsonPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectPortalClientComponent implements OnInit {
  private readonly authenticatorService = inject(AuthenticatorService);
  protected readonly availableClientsLoaded = signal(false);
  protected readonly availableClients = signal([]);
  protected readonly selectedClientFormControl = new FormControl(null, {
    validators: [Validators.required]
  });

  ngOnInit(): void {
    this.authenticatorService.selectClientLinkNavigated().subscribe(clients => {
      if (clients) {
        this.availableClientsLoaded.set(true);
        this.availableClients.set(clients);
      }
    });
  }

  selectClient(): void {
    if (this.selectedClientFormControl.invalid) {
      this.selectedClientFormControl.markAsDirty();
      this.selectedClientFormControl.updateValueAndValidity();
      return;
    }
    this.authenticatorService
      .selectClient(this.selectedClientFormControl.value)
      .subscribe(({ returnUrl }) => this.authenticatorService.redirectToSource(returnUrl));
  }
}
