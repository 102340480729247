@if (availableClientsLoaded()) {
  @if (availableClients().length === 0) {
    <empty-data-block
      class="block"
      titleStyleClass="mt-5 font-weight-semibold text-4xl line-height-2rem"
      title="There are no clients to select. Connect tech support."
    ></empty-data-block>
  } @else {
    <div class="w-full p-4 flex flex-column align-items-center">
      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content
            [formControl]="selectedClientFormControl"
            label="Select Client"
          />
        </ng-template>
        <ng-template #inputContent>
          <prime-single-dropdown
            [options]="availableClients()"
            optionValue="id"
            optionLabel="name"
            dataKey="id"
            placeholder="Client"
            [formControl]="selectedClientFormControl"
            styleClass="w-20rem"
          ></prime-single-dropdown>
        </ng-template>
        <ng-template #validationContent>
          <prime-input-validation
            [formControl]="selectedClientFormControl"
          ></prime-input-validation>
        </ng-template>
      </prime-input-container>

      <prime-button
        class="mt-3"
        (onClick)="selectClient()"
        severity="primary"
        label="Select"
      ></prime-button>
    </div>
  }
} @else {
  <pinnakl-local-spinner-simple-mode-container
    containerHeight="400px"
    containerWidth="100%"
    size="100px"
  ></pinnakl-local-spinner-simple-mode-container>
}
