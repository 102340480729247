import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticatorService } from '../../services/authenticator.service';

interface LoginForm {
  username: FormControl<string>;
  password: FormControl<string>;
  remember: FormControl<boolean>;
}

interface Vm {
  errorMessage: string | null;
  version: string | null;
}

@Component({
  selector: 'pinnakl-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  vm$: Observable<Vm>;
  form: FormGroup<LoginForm> = new FormGroup<LoginForm>({
    username: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    remember: new FormControl(false, { nonNullable: true })
  });
  submitted = false;
  userName: string | null = null;
  userType: string | null = null;

  constructor(
    private readonly router: Router,
    private readonly authenticator: AuthenticatorService
  ) {
    this.vm$ = combineLatest([this.authenticator.errorMessage$, this.authenticator.version$]).pipe(
      map(([errorMessage, version]) => {
        return { errorMessage, version };
      })
    );
    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.form.valid) this.authenticator.errorMessage$.next(null);
    });
  }

  ngOnInit(): void {
    this.setUserName();
    this.userType = this.authenticator.userType;
  }

  submit(): void {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    const { username, password } = this.form.getRawValue();
    this.authenticator.login({ username: username.trim(), password: password.trim() });
  }

  goToPasswordReset(): void {
    this.router.navigate(['/', 'reset-password'], { queryParamsHandling: 'preserve' });
  }

  private setUserName(): void {
    const nameFromStorage = localStorage.getItem('name');
    this.userName =
      nameFromStorage && nameFromStorage !== 'null' && nameFromStorage !== 'undefined'
        ? nameFromStorage
        : null;
  }
}
