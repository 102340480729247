import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  InputContainerComponent,
  InputValidationComponent,
  LabelContentComponent,
  PrimeButtonComponent,
  PrimeInputPasswordComponent,
  PrimeInputTextComponent
} from '@pinnakl/shared/ui/prime';
import { CodeInputComponent } from './components/2fa-code-input/2fa-code-input.component';
import { TFAComponent } from './components/2fa/2fa.component';
import { AuthPageComponent } from './components/auth-page/auth-page.component';
import { LoginComponent } from './components/login/login.component';
import { MagicLinkComponent } from './components/magic-link/magic-link.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AuthCacheService } from './services/auth-cache.service';
import { AuthenticatorService } from './services/authenticator.service';

@NgModule({
  declarations: [
    AuthPageComponent,
    LoginComponent,
    TFAComponent,
    MagicLinkComponent,
    ResetPasswordComponent,
    CodeInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    PrimeInputTextComponent,
    InputContainerComponent,
    LabelContentComponent,
    PrimeInputPasswordComponent,
    PrimeButtonComponent,
    InputValidationComponent
  ],
  providers: [AuthenticatorService, AuthCacheService]
})
export class AuthenticatorModule {}
