import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TFAComponent } from './auth/components/2fa/2fa.component';
import { AuthPageComponent } from './auth/components/auth-page/auth-page.component';
import { LoginComponent } from './auth/components/login/login.component';
import { MagicLinkComponent } from './auth/components/magic-link/magic-link.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { SelectPortalClientComponent } from './auth/components/select-portal-client/select-portal-client.component';

const routes: Routes = [
  {
    path: '',
    component: AuthPageComponent,
    children: [
      { path: 'mlink', component: MagicLinkComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'select-client', component: SelectPortalClientComponent },
      { path: '2fa', component: TFAComponent },
      { path: '', redirectTo: 'login', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
