import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StorageService } from './storage.service';

/**
 * Local storage service
 * used for persist application data in observable key value pair
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends StorageService {
  /**
   * Constructor with service injection
   * @param window
   */
  constructor(@Inject(DOCUMENT) private document: any) {
    const window = document.defaultView;
    super(window.localStorage);
  }
}
