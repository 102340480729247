import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnvironmentModule } from '@pinnakl/core/environment';
import { PinnaklGlobalSpinnerComponent } from '@pinnakl/shared/base-components';
import { HTTP_SERVER_URL, LOCATION } from '@pinnakl/shared/constants';
import { UiToastMessageService } from '@pinnakl/shared/ui/prime';
import { PageSubscriptionsHandler } from '@pinnakl/shared/util-helpers';
import { PinnaklErrorHandler, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticatorModule } from './auth/authenticator.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EnvironmentModule.forRoot({ config: environment }),
    HttpClientModule,
    AppRoutingModule,
    AuthenticatorModule,
    ToastModule,
    PinnaklGlobalSpinnerComponent
  ],
  providers: [
    MessageService,
    {
      provide: PinnaklUIToastMessage,
      useClass: UiToastMessageService
    },
    {
      provide: HTTP_SERVER_URL,
      useValue: environment.coreApiServerUrl
    },
    {
      provide: LOCATION,
      useValue: location
    },
    {
      provide: ErrorHandler,
      useClass: PinnaklErrorHandler
    },
    PageSubscriptionsHandler
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
