import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OtpChannelType } from '@pinnakl/shared/types';
import { stringBooleanToBoolean } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { Observable } from 'rxjs';
import { AuthCacheService } from '../../services/auth-cache.service';
import { AuthenticatorService } from '../../services/authenticator.service';

interface TFAForm {
  otp: FormControl<string | null>;
}

@UntilDestroy()
@Component({
  selector: 'two-factor-auth',
  styleUrls: ['./2fa.component.scss'],
  templateUrl: '2fa.component.html'
})
export class TFAComponent implements OnInit {
  form = new FormGroup<TFAForm>({
    otp: new FormControl(null, { nonNullable: false })
  });
  submitted = false;
  otpInputDigitsLeft = 6;
  otpInputCompleted = false;
  otpChannel?: OtpChannelType;
  isResetPasswordQr: boolean;
  sourceAppURL$?: Observable<string | null>;
  credentials$?: Observable<{ username: string; password: string } | null>;

  constructor(
    private readonly toastr: PinnaklUIToastMessage,
    private readonly authenticator: AuthenticatorService,
    private readonly authCacheService: AuthCacheService
  ) {}

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler() {
    this.loginOtp();
  }

  ngOnInit() {
    const params = new URLSearchParams(this.authCacheService.lastURLSearch);
    this.isResetPasswordQr = stringBooleanToBoolean(params.get('resetPasswordQr'));
    this.otpChannel = <OtpChannelType>params.get('otpChannel') || OtpChannelType.QR;
    this.sourceAppURL$ = this.authCacheService.sourceAppURL$;
    this.credentials$ = this.authCacheService.credentials$;
  }

  onSubmit(form: FormGroup<TFAForm>): void {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    const { otp } = form.value;
    if (this.isResetPasswordQr) {
      this.authenticator.verifyQrOtp(otp);
      return;
    }
    switch (this.otpChannel.toUpperCase()) {
      case OtpChannelType.QR.toUpperCase(): {
        this.authenticator.verifyQrOtp(otp);
        break;
      }
      case OtpChannelType.EMAIL.toUpperCase():
      case OtpChannelType.MOBILE.toUpperCase(): {
        this.authenticator.verifyOtp(otp);
      }
    }
  }

  codeChanged(event: string): void {
    this.form.get('otp')?.patchValue(event.toString());
    this.otpInputDigitsLeft = 6 - event.toString().length;
    this.otpInputCompleted = false;
  }

  codeCompleted(): void {
    this.otpInputCompleted = true;
  }

  loginOtp(): void {
    this.onSubmit(this.form);
  }

  resendCode(): void {
    this.authenticator.login(this.authCacheService.credentials);
    this.toastr.success('The code was sent successfully');
  }

  backToLogin() {
    this.authCacheService.clearCredentials();
    this.authenticator.redirectToSource();
  }
}
