<ng-container *ngIf="vm$ | async as vm">
  <div class="welcome-block">
    <p
      *ngIf="userName"
      class="welcome-text"
    >
      Welcome back{{ userName ? ', ' + userName : '.' }}
    </p>
  </div>
  <div
    [ngClass]="{ hasError: vm.errorMessage }"
    class="login-form"
  >
    <form
      (keydown.enter)="form.valid && submit()"
      [class.ng-submitted]="submitted"
      [formGroup]="form"
      class="relative"
    >
      <div class="mb-3">
        <prime-input-container>
          <ng-template #labelContent>
            <prime-label-content
              formControlName="username"
              label="Email"
            />
          </ng-template>
          <ng-template #inputContent>
            <prime-text-input
              autocomplete="email"
              class="flex w-100"
              formControlName="username"
            />
          </ng-template>
        </prime-input-container>
      </div>

      <div
        (click)="goToPasswordReset()"
        class="forgot-pass absolute right-0 font-semibold text-sm cursor-pointer"
      >
        <a>Forgot Password?</a>
      </div>

      <div class="mb-3">
        <prime-input-container>
          <ng-template #labelContent>
            <prime-label-content
              formControlName="password"
              label="Password"
            />
          </ng-template>
          <ng-template #inputContent>
            <prime-input-password
              [feedback]="false"
              [toggleMask]="true"
              autocomplete="current-password"
              class="flex w-100"
              formControlName="password"
              inputStyleClass="w-100"
            />
          </ng-template>
        </prime-input-container>
      </div>

      <div class="flex justify-content-between">
        <div class="flex gap-2 align-items-center">
          <input
            formControlName="remember"
            id="remember"
            type="checkbox"
          />
          <label
            class="mb-0"
            for="remember"
            >Remember me</label
          >
        </div>
        <prime-button
          (onClick)="submit()"
          [rounded]="true"
          [style]="{ 'letter-spacing': '1px' }"
          label="Login"
          severity="primary"
        ></prime-button>
      </div>
      <div>
        <div class="versionNo">Version No: {{ vm.version }}</div>
      </div>
    </form>
    <div class="error-container text-center py-4 mt-3">
      {{ vm.errorMessage ?? '&nbsp;' }}
    </div>
  </div>
</ng-container>
